import React, { useEffect } from "react";
import NextHead from "next/head";
import { string } from "prop-types";
import { initGA, logPageView } from "../lib/analytics";

const Head = (props) => {
                          // useEffect(() => {
                          //   if (!window.GA_INITIALIZED) {
                          //     initGA();
                          //     window.GA_INITIALIZED = true;
                          //   }
                          //   logPageView();
                          // }, []);

                          return (
                            <NextHead>
                              <meta charSet="UTF-8" />
                              <title>{props.title || ""}</title>
                              <meta
                                name="viewport"
                                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                              />
                              <link rel="icon" href="/favicon.ico" />
                              <link
                                href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;700&display=swap"
                                rel="stylesheet"
                              />
                            </NextHead>
                          );
                        };

Head.propTypes = {
  title: string,
  description: string,
  url: string,
  ogImage: string,
};

export default Head;
